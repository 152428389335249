/* Ajax loader */
body #loader { display: none; }
body.loading #loader {
    position: fixed;
    display: block;
    top: 0em;
    left: 49%;
    width: 7em;
    height: 2em;
    z-index: 1000;
    font-size: 1em;
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
    border-style: none;
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    opacity: 0.8;
}
