/* CSS Styles for the SENAITE Setup View*/

#setupview .vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

#setupview .tile {
    width: 100%;
    height: 6em;
    box-sizing: border-box;
    display: inline-block;
    padding: 2em;
    margin: auto auto 1em auto;
    overflow: hidden;
    border: 1px solid $border-color;
}

#setupview .tile .title {
    text-transform: uppercase;
    font-weight: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 15px;
}

#setupview .tile:hover {
    background-color: $gray-200;
}

#setupview .tile:focus {
    background-color: $gray-200;
    color: #2d5e77;
}
