/* CSS Styles for the SENAITE Sidebar */
.wrapper {
    display: flex;
    min-height: calc(100% - 64px);  // subtract toolbar
    div.container-fluid {
        width: calc(100% - 50px);  // subtract sidebar
    }
}

#sidebar {
    min-width: $sidebar-maximized-width;
    max-width: $sidebar-maximized-width;
    transition: all 0.3s;
    overflow: hidden;
    white-space: nowrap;
    background-color: $senaite-bg-lightgrey !important;
}
#sidebar #sidebar-header {
    text-align: right;
    button:focus {
        box-shadow: none;
    }
}
#sidebar:not(.minimized) ul li:hover {
    color: #000;
    transition: all 0.1s;
    opacity: 1;
}
#sidebar.minimized {
    min-width: $sidebar-minimized-width;
    max-width: $sidebar-minimized-width;
}
#sidebar.minimized .sidebar-toggle-icon {
    @extend .fa;
    @extend .fa-toggle-off;
}
#sidebar.toggled .sidebar-toggle-icon {
    @extend .fa;
    @extend .fa-toggle-on;
}
#sidebar.minimized ul li a {
    padding: 15px 0px;
    text-align: center;
}
#sidebar.minimized ul ul a {
    padding: 15px 17px !important;
}
#sidebar.minimized ul li a i {
    margin-right: 0;
    margin-bottom: 5px;
}
#sidebar.minimized ul li a span.node-title,
#sidebar.minimized ul li a span.child-title {
    display: none;
}
#sidebar ul li.active>a {
    color: $sidebar-selected-color;
}
#sidebar ul li.active,
#sidebar ul li.expanded {
    background: $sidebar-selected-bg;
}

/* Sidebar maximized */
#sidebar.minimized #sidebar-header {
    text-align: center;
}
#sidebar .sidebar-toggle-icon {
    @extend .fa;
    @extend .fa-toggle-off;
}
#sidebar a {
    color: $sidebar-color;
}
#sidebar a:hover {
    color: $sidebar-hover-color;
}
#sidebar ul li a {
    padding: 15px 17px;
    display: block;
}

/* hide deactivated items from sidebar */
#sidebar li a.senaite-state-inactive,
#sidebar.minimized li a.senaite-state-inactive {
    display: none;
}

/* submenu */
#sidebar ul.submenu {
    max-width: $sidebar-maximized-width;
}
#sidebar ul.submenu li {
    width: $sidebar-maximized-width;
}
#sidebar.minimized ul.submenu li {
    padding: 0;
    width: $sidebar-minimized-width;
}
#sidebar ul.nav-level-1 li.active,
#sidebar ul.nav-level-1 li.expanded,
#sidebar.minimized ul.nav-level-1 li.active,
#sidebar.minimized ul.nav-level-1 li.expanded {
    background-: $sidebar-selected-bg-1;
    &:hover {
        background: $sidebar-selected-bg-1;
    }
}
#sidebar ul.nav-level-2 li.active,
#sidebar.minimized ul.nav-level-2 li.active {
    background: $sidebar-selected-bg-2;
    &:hover {
        background: $sidebar-selected-bg-2;
    }
}

/* Media Queries */
@media (max-width: 768px) {
    // #sidebar-header {
    //     display: none;
    // }
}
